<template>
<div id="app">
    <div id="logovanje">
<h1>Електронски тестови</h1>
<div style="width:46%;display: inline-block; border-right: solid 1px #ff0000; padding: 20px; vertical-align: top;">
    <h3>Пријава</h3>
<form> 
     <div class="form-horizontal">
         <div v-show="!pass" style="color:red">{{msg}}</div>
                <div class="form-group">
                <label class="control-label col-md-2" for="Naslov">Емаил</label>
                <div class="col-md-10">
                    <input class="form-control text-box single-line valid" id="Email" name="Email" v-model="email"  type="text" value="" aria-invalid="false">
                    <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                </div>
            </div>


            <div class="form-group">
                <label class="control-label col-md-2" for="Naslov">Лозинка</label>
                <div class="col-md-10">
                    <input class="form-control text-box single-line valid" id="Lozinka" name="Lozinka" v-model="password" type="password" value="" aria-invalid="false">
                    <span class="field-validation-valid text-danger" data-valmsg-for="Lozinka" data-valmsg-replace="true"></span>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-offset-2 col-md-10">
                     <button type="submit" @click.prevent="process" class="btn btn-primary">Пријава</button>
                </div>
            </div>
        </div>
</form></div>

<div style="width:45%; display: inline-block;padding: 20px; vertical-align: top;">
    <h3>Регистрација</h3>
    <div align="center">
        Да би сте се тестирали, потребно је да имате кориснички налог.

    </div>
    <br>
    <div class="form-group" align="center">
        <div class="col-md-offset-2 col-md-10" style="margin-left: auto !important">
            <a href="/registracija.html">КРЕИРАЈ НОВИ НАЛОГ</a>
        </div>
    </div>
</div>
</div>
</div>
</template>

<script>

import axios from 'axios'
import appConfig from '../js/configuration.js'

export default {
  name: 'Logovanje',
   data: function() {
        return {
      email: '',
      password: '',
      msg: '',
      pass: true
      }
    },
    methods: {
        process: function(){
            var _this = this;
            var postData = new FormData();
            postData.append('email',this.email);
            postData.append('password', this.password);

            axios.post(appConfig.backendUrl + 'login.php',postData).then(function(response){
                //console.log(_this);
                  //console.log(response.data);
                if(response.data.result === true){
                   _this.pass = true;
                   localStorage.setItem("userToken", response.data.token);
                   _this.msg = '';
                     _this.$emit('updateNaslovna', response.data.result); 
                }
                else{
                   _this.pass = false;
                   _this.msg = 'Неуспела пријава';
                }
               
            });
          //console.log('process');
        }
    },
}
</script>

